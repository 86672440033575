<template>
  <b-row>
    <!-- <section> -->
    <b-col md="6" sm="6">
      <b-card no-body>
        <b-card-body>
          <b-card-title>
            <b-row>
              <b-col>
                <div>Filter Jurusan</div>
              </b-col>
            </b-row>
          </b-card-title>

          <b-card-text class="blog-content-truncate">
            <b-row>
              <b-col md="12" sm="12" class="">
                <label>Pilih Rumpun</label>
                <v-select
                  label="text"
                  v-model="filterRumpun"
                  class="select-size-sm w-full"
                  @input="
                    getMajor();
                    getJurusan();
                  "
                  :clearable="true"
                  :options="optRumpun"
                  placeholder="- All -"
                />
              </b-col>
              <b-col md="12" sm="12" class="">
                <label>Pilih Sekolah/Universitas</label>
                <v-select
                  class="select-size-sm w-full"
                  label="text"
                  v-model="filterMajor"
                  @input="getJurusan()"
                  :clearable="true"
                  :options="optMajor"
                  placeholder="- All -"
                />
              </b-col>
              <b-col md="12" sm="12" class="">
                <label>Pilih Jurusan</label>
                <v-select
                  label="text"
                  v-model="filterJurusan"
                  class="select-size-sm w-full"
                  @input="getRanking()"
                  :clearable="true"
                  :options="optJurusan"
                  placeholder="- All -"
                />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col md="6" sm="6">
      <b-card no-body>
        <b-card-body>
          <b-card-title>
            <b-row>
              <b-col>
                <div>Filter Provinsi</div>
              </b-col>
            </b-row>
          </b-card-title>

          <b-card-text class="blog-content-truncate">
            <b-row>
              <b-col md="12" sm="12" class="">
                <label>Pilih Provinsi</label>
                <v-select
                  label="text"
                  v-model="filterProv"
                  class="select-size-sm w-full"
                  @input="getRanking()"
                  :clearable="true"
                  :options="optProv"
                  placeholder="- All -"
                />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col cols="12">
      <!-- <section> -->
      <b-card no-body>
        <b-card-body>
          <!-- <b-card-title>
        <b-row>
          <b-col>
            <div>Ranking SNBP</div>
          </b-col>
          <b-col>
            <div align="right">
            </div>
          </b-col>
        </b-row>
      </b-card-title> -->
          <b-card-text class="blog-content-truncate">
            <b-row>
              <b-col md="3" sm="4" class="my-1">
                <b-form-group class="mb-0">
                  <label class="d-inline-block text-sm-left mr-50">Per page</label>
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="w-50"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" sm="8" class="my-1">
                <b-form-group
                  label="Sort"
                  label-cols-sm="2"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-select
                      id="sortBySelect"
                      v-model="sortBy"
                      :options="sortOptions"
                      class="w-50"
                    >
                      <template v-slot:first>
                        <option value="">-- none --</option>
                      </template>
                    </b-form-select>
                    <b-form-select
                      v-model="sortDesc"
                      size="sm"
                      :disabled="!sortBy"
                      class="w-30"
                    >
                      <option :value="false">Asc</option>
                      <option :value="true">Desc</option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="5" class="my-1">
                <b-form-group label-for="filterInput" class="mb-0">
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Search......"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <div>
                  <b-table
                    striped
                    small
                    hover
                    responsive
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    :fields="fields"
                    :items="snbpranking"
                  >
                    <template #thead-top="data">
                      <tr>
                        <th colspan="4"></th>
                        <th variant="secondary" colspan="2">Pilihan 1</th>
                        <th variant="primary" colspan="2">Pilihan 2</th>
                      </tr>
                    </template>
                    <!-- A virtual column -->
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>

                    <!-- A custom formatted column -->
                    <template #cell(action)="row">
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item @click="ModalUbah(row.item)">
                          <feather-icon icon="Edit2Icon" class="mr-50" />
                          <span>Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="ModalHapus(row.item)">
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span>Hapus</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <!-- A custom formatted column -->
                    <template #cell(Popularity)="data">
                      <b-progress
                        :value="data.value.value"
                        max="100"
                        :variant="data.value.variant"
                        striped
                      />
                    </template>

                    <template #cell(color)="data">
                      <b-badge :variant="data.item.color">
                        {{ data.item.color }}
                      </b-badge>
                    </template>

                    <!-- A virtual composite column -->
                    <template #cell(price)="data">
                      {{ "$" + data.value }}
                    </template>

                    <template #cell(voucher.value)="data">
                      {{
                        data.item.voucher.type == "nominal"
                          ? "Rp" + formatRupiah(data.item.voucher.value)
                          : data.item.voucher.value + "%"
                      }}
                    </template>
                    <template #cell(voucher.type)="data">
                      {{
                        data.item.voucher.type == "nominal"
                          ? "Nominal (Rp)"
                          : "Persentase (%)"
                      }}
                    </template>

                    <!-- Optional default data cell scoped slot -->
                    <template #cell()="data">
                      {{ data.value }}
                    </template>
                  </b-table>
                </div>
                <!-- <b-pagination
              v-if="record !== 0"
              :total="Math.ceil((total * 1) / itemsPerPage)"
              v-model="currentPage"
              @change="staf()"
            /> -->
                <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
              :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="center"
                  size="sm"
                  class="my-0"
                />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body> </b-card
    ></b-col>
  </b-row>
  <!-- </section> -->
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  // props: ["paketDataId"],
  components: {
    vSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      snbpranking: [],
      voucherData: [],
      filterRumpun: null,
      filterMajor: null,
      filterJurusan: null,
      filterProv: null,
      optProv: [],
      optRumpun: [],
      optMajor: [],
      optJurusan: [],
      // paketDataId: {
      //   name: null,
      // },
      form: {
        id: null,
        voucher_id: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "ranking.ke", label: "Ranking" },
        { key: "name", label: "Nama" },
        { key: "school_origin.name", label: "Asal Sekolah" },
        { key: "school_origin.provinsi_name", label: "Provinsi" },
        { key: "majors[0].major.name", label: "Jurusan 1" },
        { key: "majors[0].nilai_akhir", label: "Nilai Akhir 1", sortable: true },
        { key: "majors[1].major.name", label: "Jurusan 2" },
        { key: "majors[1].nilai_akhir", label: "Nilai Akhir 2", sortable: true },
        // { key: "ranking.ke", label: "Ranking", sortable: true },
        // { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "text", value: "Voucher" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Voucher Paket",
      search: null,
      idd: null,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Voucher Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Voucher Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("snbp/createUpdateDeleteVoucher", [data])
            .then(() => {
              this.getRanking();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (
        (this.form.voucher_id == null && this.form.voucher_id == null) ||
        (this.form.voucher_id == "" && this.form.voucher_id == "")
      ) {
        this.pesanGagal();
        return false;
      }
      let payload = {
        // text: this.form.text,
        voucher_id: this.form.voucher_id,
        // paket_id: this.$route.params.id,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("snbp/createUpdateDeleteVoucher", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          // this.ModalEdit = false;
          this.getRanking();
          this.displaySuccess({
            text: "Voucher SNBP berhasil di" + this.activeAction,
          });
          // this.form.text = null;
          this.Modal = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getRanking() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // category_paket_id: this.filterRumpun != null ? this.filterRumpun.value : null,
        // mapel_id: this.filterRumpun != null ? this.filterRumpun.value : null,
        major_id: this.filterJurusan != null ? this.filterJurusan.id : null,
        provinsi: this.filterProv != null ? this.filterProv.value : null,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("snbp/indexRanking", payload)
        .then((response) => {
          let snbpranking = response.data.data;
          this.snbpranking = snbpranking;
          this.totalRows = this.snbpranking.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    // async getDataById() {
    //   this.$store
    //     .dispatch("paketTryout/indexId", this.$route.params.id)
    //     .then((response) => {
    //       let paketDataId = response.data;
    //       this.paketDataId = paketDataId;
    //     })
    //     .catch((error) => {
    //       this.$root.$emit("errorData", error);
    //     });
    // },

    async getJurusan() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // paket_id: this.$route.params.id,
        rumpun_id: this.filterRumpun != null ? this.filterRumpun.value : null,
        school_id: this.filterMajor != null ? this.filterMajor.value : null,
      };
      this.$store
        .dispatch("jurusan/index", payload)
        .then((response) => {
          let optJurusan = response.data.data;
          // optJurusan.unshift({ id: null, name: "- Pilih Jurusan -" });
          optJurusan.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optJurusan = optJurusan;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getRumpun() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // paket_id: this.$route.params.id,
        // rumpun_id: this.filterRumpun != null ? this.filterRumpun.value : null,
        // school_id: this.filterMajor != null ? this.filterMajor.value : null,
      };
      this.$store
        .dispatch("rumpun/index", payload)
        .then((response) => {
          let optRumpun = response.data.data;
          // optRumpun.unshift({ id: null, name: "- Pilih Jurusan -" });
          optRumpun.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optRumpun = optRumpun;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getMajor() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // paket_id: this.$route.params.id,
        rumpun_id: this.filterRumpun != null ? this.filterRumpun.value : null,
        // school_id: this.filterMajor != null ? this.filterMajor.value : null,
      };
      this.$store
        .dispatch("masterUniv/index", payload)
        .then((response) => {
          let optMajor = response.data.data;
          // optMajor.unshift({ id: null, name: "- Pilih Major -" });
          optMajor.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optMajor = optMajor;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getProv() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        // paket_id: this.$route.params.id,
        rumpun_id: this.filterRumpun != null ? this.filterRumpun.value : null,
        // school_id: this.filterProv != null ? this.filterProv.value : null,
      };
      this.$store
        .dispatch("provinsi/index", payload)
        .then((response) => {
          let optProv = response.data.data;
          // optProv.unshift({ id: null, name: "- Pilih Prov -" });
          optProv.map((item) => {
            item.value = item.id;
            item.text = item.nama_provinsi;
          });
          this.optProv = optProv;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {},
  mounted() {
    this.getRanking();
    this.getRumpun();
    this.getProv();
  },
};
</script>

<style>
/* .scroll {
  max-height: 100px;
  overflow-y: scroll;
} */
</style>
