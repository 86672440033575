<template>
  <b-tabs align="center" fill nav-class="flex-nowrap overflow-auto white-space-nowrap;
max-width-500px">
    <b-tab active>
      <template #title>
        <feather-icon icon="ListIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">List</span>
      </template>
      <b-card no-body>
        <b-card-body>
          <b-card-title>
            <b-row>
              <b-col>
                <div>List SNBP</div>
              </b-col>
              <b-col>
                <div align="right">
                  <!-- <b-button variant="outline-primary" v-b-modal.modal-sm @click="ModalShow()" class="btn-icon"> <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button> -->
                </div>
                <b-modal
                  v-model="Modal"
                  id="modal-tambah"
                  centered
                  size="sm"
                  :title="titleModal"
                >
                  <b-card-text>
                    <b-form>
                      <b-form-group label="Nama" label-for="nama">
                        <b-form-input id="nama" v-model="form.name" placeholder="Nama" />
                      </b-form-group>
                    </b-form>
                  </b-card-text>

                  <template #modal-footer>
                    <div class="w-100">
                      <p class="float-left mb-0"></p>

                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="outline-success"
                        class="mr-1 btn-icon"
                        @click.prevent="Tambah"
                      >
                        <feather-icon icon="SaveIcon" class="mr-25" />{{
                          label || "Tambah"
                        }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-primary"
                        class="float-right btn-icon"
                        @click="Modal = false"
                      >
                        <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                      </b-button>
                    </div>
                  </template>
                </b-modal>
              </b-col></b-row
            >
          </b-card-title>
          <b-card-text class="blog-content-truncate">
            <b-row>
              <b-col md="3" sm="4" class="my-1">
                <b-form-group class="mb-0">
                  <label class="d-inline-block text-sm-left mr-50">Per page</label>
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="w-50"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" sm="8" class="my-1">
                <b-form-group
                  label="Sort"
                  label-cols-sm="2"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-select
                      id="sortBySelect"
                      v-model="sortBy"
                      :options="sortOptions"
                      class="w-50"
                    >
                      <template v-slot:first>
                        <option value="">-- none --</option>
                      </template>
                    </b-form-select>
                    <b-form-select
                      v-model="sortDesc"
                      size="sm"
                      :disabled="!sortBy"
                      class="w-30"
                    >
                      <option :value="false">Asc</option>
                      <option :value="true">Desc</option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="5" class="my-1">
                <b-form-group label-for="filterInput" class="mb-0">
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Search......"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

              <b-col cols="12">
                <b-table
                  striped
                  small
                  hover
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  :fields="fields"
                  :items="data"
                >
                  <!-- A virtual column -->
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <!-- A custom formatted column -->
                  <template #cell(action)="row">
                    <b-button
                      variant="outline-info"
                      class="btn-icon"
                      @click="ModalUbah(row.item)"
                    >
                      <feather-icon icon="EditIcon"
                    /></b-button>
                    <b-button
                      variant="outline-danger"
                      class="btn-icon ml-1"
                      @click="ModalHapus(row.item)"
                    >
                      <feather-icon icon="TrashIcon"
                    /></b-button>
                  </template>

                  <template #cell(cocok)="data">
                    <div v-for="(item, i) in data.item.result" :key="i">
                      <b-badge variant="success">
                        <span v-html="item.major.school.name + ' - ' + item.major.name" />
                      </b-badge>
                      <span v-html="item.description.kecocokan" />
                    </div>

                    <!-- <span>{{ data.result[1].description.kecocokan }}</span> -->
                  </template>
                  <template #cell(lulus)="data">
                    <div v-for="(item, i) in data.item.result" :key="i">
                      <b-badge variant="success">
                        <span v-html="item.major.school.name + ' - ' + item.major.name" />
                      </b-badge>
                      <span v-html="item.description.kelulusan" />
                    </div>
                  </template>
                  <template #cell(name)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(school_origin)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(sekolah)="data">
                    <div v-if="data.item.school_origin_info != null">

                      {{ data.item.school_origin_info.nama_sekolah }}
                    </div>
                    <div v-else>
                      {{ data.item.school_origin }}
                    </div>
                  </template>
                  <template #cell(school_accreditation)="data">
                    {{ data.value }}
                  </template>

                  <template #cell(created_at)="data">
                    {{ humanDate(data.value) }}
                  </template>

                  <template #cell(detail)="data">
                    <b-button size="sm" variant="outline-dark" @click="detail(data)"
                      ><feather-icon icon="EyeIcon"
                    /></b-button>
                  </template>
                </b-table>
                <!-- <b-pagination
          v-if="record !== 0"
          :total="Math.ceil((total * 1) / itemsPerPage)"
          v-model="currentPage"
          @change="staf()"
        /> -->
                <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
          :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="center"
                  size="sm"
                  class="my-0"
                />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body>
      </b-card>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="PercentIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Voucher</span>
      </template>

      <voucher-snbp />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="AwardIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Ranking</span>
      </template>

      <ranking-snbp />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="BookIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Mata Pelajaran SNBP</span>
      </template>

      <mapel-snbp />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="FileTextIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Setting Deskripsi</span>
      </template>

      <deskripsi-snbp />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Setting SNBP</span>
      </template>

      <setting-snbp />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SettingSnbp from "./Setting.vue";
import VoucherSnbp from "./Voucher.vue";
import RankingSnbp from "./Ranking.vue";
import DeskripsiSnbp from "./Deskripsi.vue";
import MapelSnbp from "../mata-pelajaran/Index.vue";

export default {
  components: {
    SettingSnbp,
    VoucherSnbp,
    RankingSnbp,
    DeskripsiSnbp,
    MapelSnbp,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      data: [],
      form: {
        id: null,
        name: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Nama", sortable: true },
        {
          key: "sekolah",
          label: "Asal Sekolah ",
          sortable: true,
        },
        { key: "school_accreditation", label: "Akreditasi Sekolah", sortable: true },
        { key: "cocok", label: "Kecocokan", sortable: true },
        { key: "lulus", label: "Kelulusan", sortable: true },
        // { key: "created_at", label: "Tanggal Daftar", sortable: true },
        { key: "detail", label: "" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Nama" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori",
      search: null,
      idd: null,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah SMBP";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah SMBP";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("kategoriArtikel/save", [data])
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Sukses",
            icon: "CheckIcon",
            text: "Tambah",
            variant: "SMBP Berhasil ditambah",
          },
        },
        {
          position: "bottom-right",
        }
      );
      // if (this.form.name == null || this.form.name == "") {
      //   this.pesanGagal();
      //   return false;
      // }
      // let payload = {
      //   name: this.form.name,
      // };

      // if (this.id) {
      //   payload.id = this.id;
      // }

      // this.$store
      //   .dispatch("kategoriArtikel/save", [payload])
      //   .then(() => {
      //     if (this.activeAction == "tambah") {
      //       this.id = null;
      //     }
      //     this.form.name = null;
      //     this.Modal = false;
      //     // this.ModalEdit = false;
      //     this.getData();
      //     this.displaySuccess({
      //       text: "Kategori berhasil di" + this.activeAction,
      //     });
      //   })
      //   .catch((e) => {
      //     this.displayError(e);
      //     return false;
      //   });
    },
    getData() {
      const params = {};
      this.$store
        .dispatch("snbp/list", params)
        .then((response) => {
          let data = response.data.data;
          this.data = data;
          this.totalRows = this.data.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    detail(data) {
      this.$store.commit("snbp/SET_DETAIL", data.item);
      this.$router.push({ name: "admin-snbp-detail" });
    },
  },
  created() {},
  async mounted() {
    this.getData();
  },
};
</script>

<style></style>
